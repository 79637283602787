import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { change } from 'redux-form';
import { UPDATE_PROFILE_SUCCESS, listVehicles, modalsToggle, snackShow } from '../../actions';
import { setActiveVehicleId } from '../sidePanel/sidePanel';
import { User } from '../../types/user';

const API_URL = import.meta.env.VITE_API_URL;

console.log(API_URL, 'API_URL');

const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('token');
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: [
    'MyBids',
    'OfferAuction',
    'SidePanelBids',
    'AuctionVehicles',
    'MyBidsCounters',
    'RelistHistory',
    'VehiclesPool',
    'AuctionsList',
    'MobileMyBids',
    'Me',
    'IsUserEditing',
    'ListerListRequests',
    'PoolRequestsByDay',
    'BuyerTransactions'
  ],
  endpoints: builder => ({
    stopAuction: builder.mutation<void, string>({
      query: id => ({
        url: `/auctions/${id}/finish`,
        method: 'POST'
      })
    }),
    editNotificationsSettings: builder.mutation<
      void,
      { visualNotificationsEnabled: boolean; audioNotificationsEnabled: boolean; userId: number }
    >({
      query: ({ userId, ...body }) => ({
        url: `/users/${userId}/settings/notifications`,
        method: 'PUT',
        body
      }),
      onQueryStarted: async (args, { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }) => {
        await queryFulfilled;
        const { visualNotificationsEnabled, audioNotificationsEnabled } = args;

        const localStorageUser = JSON.parse(localStorage.getItem('user') || '{}');
        if (Number(localStorageUser.id) !== Number(args.userId)) {
          return;
        }
        localStorageUser.visual_notifications_enabled = visualNotificationsEnabled;
        localStorageUser.audio_notifications_enabled = audioNotificationsEnabled;
        localStorage.setItem('user', JSON.stringify(localStorageUser));

        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: {
            data: {
              visual_notifications_enabled: visualNotificationsEnabled,
              audio_notifications_enabled: audioNotificationsEnabled
            }
          }
        });
      }
    }),
    getCarEstimate: builder.mutation<any, any>({
      query: body => ({
        url: '/car_estimate',
        method: 'POST',
        body
      }),
      transformResponse: (response: any) => response.data,
      onQueryStarted: async (args, { dispatch, getState, queryFulfilled, requestId, extra, getCacheEntry }) => {
        const data = await queryFulfilled;
        const predictions = data?.data;
        const [estimate] = predictions ?? [];

        const bluebook = estimate || {
          configuredValue: 0,
          mileageAdjustment: 0
        };

        dispatch(change('addVehicleForm', 'bluebookValue', bluebook.configuredValue - bluebook.mileageAdjustment));

        dispatch(change('addVehicleForm', 'bluebookMileage', bluebook.configuredValue));
        dispatch(change('addVehicleForm', 'bluebook_mileage_adjustment', bluebook.mileageAdjustment));
        const goodConditionEstimate = predictions.find((item: any) => item.condition && item.condition === 'Good');
        if (goodConditionEstimate) {
          dispatch(change('addVehicleForm', 'bluebook_auction_value_good', goodConditionEstimate.configuredValue));
        }
      }
    }),
    updateVehicleNotes: builder.mutation<void, { id: number; notes: string }>({
      query: ({ id, notes }) => ({
        url: `/vehicles/${id}/notes`,
        body: { notes },
        method: 'POST'
      }),
      onCacheEntryAdded: async ({ id }, { cacheDataLoaded, dispatch }) => {
        try {
          await cacheDataLoaded;
          dispatch(listVehicles({ params: { location_id: 1, offset: 0 } }).request);
          dispatch(modalsToggle(`myBidsEditModal-${id}`));
          dispatch(snackShow({ hideTime: 3000, message: 'Vehicle updated.' }));
          dispatch(setActiveVehicleId(id));
        } catch (error) {}
      },
      invalidatesTags: ['MyBids']
    }),
    scanVin: builder.mutation<{ success: boolean; data: { vin: string; error: string } }, File>({
      query: file => {
        const formData = new FormData();
        formData.append('file', file);

        return {
          url: `/vehicles/vin/scan`,
          method: 'POST',
          body: formData,
          formData: true
        };
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          dispatch(snackShow({ message: 'Scanning VIN...', hideTime: 3000 }));
          const { data } = await queryFulfilled;
          if (!data.success) {
            dispatch(snackShow({ message: data.data.error, type: 'error' }));
          } else {
            dispatch(snackShow({ message: 'VIN scanned successfully' }));
          }
        } catch (e) {}
      }
    }),
    setFcmToken: builder.mutation<void, string>({
      query: token => ({
        url: 'users/fcm-token',
        method: 'POST',
        body: {
          fcm_token: token
        }
      })
    }),
    isUserEditingVehicle: builder.query<User | null, number>({
      query: vehicleId => ({
        url: `vehicles/${vehicleId}/is-editing`,
        method: 'GET'
      }),
      providesTags: ['IsUserEditing'],
      keepUnusedDataFor: 0,
      forceRefetch: () => true,
      transformResponse: (r: any) => r.data
    })
  })
});

export const {
  useGetCarEstimateMutation,
  useScanVinMutation,
  useUpdateVehicleNotesMutation,
  useEditNotificationsSettingsMutation,
  useSetFcmTokenMutation,
  useIsUserEditingVehicleQuery
} = api;

export default api;
